export function setupLanguageChangeListener() {
  document.addEventListener('turbo:load', function() {
    const languageOptions = document.querySelectorAll('.language-option');
    const languageContainer = document.getElementById('language-container');
    const currentUserId = document.body.dataset.currentUserId;
    const preferredLocale = languageContainer?.dataset.preferredLocale;
    const supportedLocales = ['fr-FR', 'en-US', 'es'];
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

    // Fonction pour mettre à jour la langue
    function updateLanguage(locale, url) {
      if (currentUserId && csrfToken) {
        return fetch(`/users/${currentUserId}/update_language`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,
            'Accept': 'application/json'
          },
          body: JSON.stringify({ preferred_language: locale })
        })
        .then(response => {
          if (response.ok) {
            window.location.href = url.toString();
          } else {
            console.error('Échec de la mise à jour de la langue.');
            alert('Impossible de changer la langue. Veuillez réessayer.');
          }
        })
        .catch(error => {
          console.error('Erreur lors de la mise à jour de la langue:', error);
          alert('Une erreur est survenue. Veuillez réessayer.');
        });
      } else {
        window.location.href = url.toString();
      }
    }

    // Gestion des clics sur les options de langue
    languageOptions.forEach(function(option) {
      option.addEventListener('click', function(event) {
        event.preventDefault();
        const locale = option.getAttribute('data-locale');
        const url = new URL(window.location.href);
        const pathSegments = url.pathname.split('/').filter(segment => segment !== '');

        // Vérifie si le premier segment de l'URL est une locale supportée
        if (pathSegments[0] && supportedLocales.includes(pathSegments[0])) {
          if (supportedLocales.includes(locale)) {
            pathSegments[0] = locale; // Met à jour le segment de langue existant
          } else {
            pathSegments.shift(); // Supprime le segment de langue
          }
        } else if (supportedLocales.includes(locale)) {
          pathSegments.unshift(locale); // Ajoute le segment de langue
        }

        // Met à jour le chemin de l'URL
        url.pathname = '/' + pathSegments.join('/');

        updateLanguage(locale, url);
      });
    });

    // Gestion du `preferredLocale` uniquement si un indicateur n'est pas présent
    if (preferredLocale && !window.location.search.includes('locale_updated=true')) {
      const locale = preferredLocale;
      const url = new URL(window.location.href);
      const pathSegments = url.pathname.split('/').filter(segment => segment !== '');

      // Vérifie si le premier segment de l'URL est une locale supportée
      if (pathSegments[0] && supportedLocales.includes(pathSegments[0])) {
        if (supportedLocales.includes(locale)) {
          pathSegments[0] = locale; // Met à jour le segment de langue existant
        } else {
          pathSegments.shift(); // Supprime le segment de langue
        }
      } else if (supportedLocales.includes(locale)) {
        pathSegments.unshift(locale); // Ajoute le segment de langue
      }

      // Met à jour le chemin de l'URL
      url.pathname = '/' + pathSegments.join('/');

      // Ajoute un paramètre pour indiquer que la langue a été mise à jour
      url.searchParams.set('locale_updated', 'true');

      updateLanguage(locale, url);
    }
  });
}
