// app/javascript/controllers/conversation_controller.js
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conversation"
export default class extends Controller {
  static values = {
    confirmMessage: String
  }

  connect() {
    // Récupérer l'élément du modal
    const modalEl = this.element.closest('.modal')
    if (modalEl) {
      // Quand le modal est entièrement affiché, on scroll en bas
      modalEl.addEventListener('shown.bs.modal', () => {
        this.scrollToBottom()
      })
    } else {
      // Si pas de modal (cas plus rare), on tente de scroller directement
      this.scrollToBottom()
    }

    // Écouter les événements Turbo Streams
    document.addEventListener('turbo:before-stream-render', (event) => {
      // On scroll au haut de la dernière div avec la classe "question" dans this.element
      setTimeout(() => {
        requestAnimationFrame(() => {
          // Utiliser querySelectorAll pour obtenir tous les éléments avec la classe "question"
          const questions = this.element.querySelectorAll('.question')
          if (questions.length > 0) {
            const lastQuestion = questions[questions.length - 1]
            // Optionnel : Ajouter des options pour scrollIntoView si nécessaire
            lastQuestion.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        })
      }, 50)
    })
  }

  scrollToBottom() {
    const questionsContainer = this.element.querySelector('.modal-body')
    if (questionsContainer) {
      // Défilement fluide vers le bas
      questionsContainer.scrollTo({
        top: questionsContainer.scrollHeight,
        behavior: 'smooth'
      })
    }
  }

  delete(event) {
    event.preventDefault()
    const url = event.target.dataset.url

    if (confirm(this.confirmMessageValue)) {
      fetch(url, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
          'Accept': 'text/vnd.turbo-stream.html'
        },
        credentials: 'same-origin'
      })
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          throw new Error("La requête a échoué.")
        }
      })
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
      .catch(error => {
        console.error("Erreur:", error)
        alert("Une erreur est survenue lors de la suppression.")
      })
    }
  }
}
