import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";
import i18n from "../helpers/i18n_config";
import Sortable from "sortablejs";

import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
} from "../helpers/dropzone";

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ["input"];
  static values = {
    existingDocuments: Array,
    fileTooBigLabel: String,
    picturePage: String,
    documentPage: String,
    companyPage: String,
    purchaseNegociationPage: String,
    acceptedFiles: String,
    action: String,
    documentName: String,
  };

  connect() {
    this.setupDropzone();
    this.setupFormErrorListener();
  }

  disconnect() {
    if (this.dropZone) {
      this.dropZone.removeAllFiles(true); // Enlève tous les fichiers de la vue sans déclencher les évènements de suppression.
      this.dropZone.destroy(); // Détruit l'instance de Dropzone.
    }
  }

  setupDropzone() {
    this.hideFileInput();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    this.dropZone = createDropZone(this);
    this.bindEvents();
    this.displayExistingDocuments();
    // this.recalculateMaxFiles(); // Ajuste maxFiles après l'affichage des fichiers existants
    this.updateFileUploadedAttribute();

    // Gestionnaire de clics pour ouvrir les fichiers dans une nouvelle fenêtre
    this.element.addEventListener('click', (event) => {
      if (event.target.matches('[data-dz-name]')) {
          const url = event.target.getAttribute('data-url');
          if (url) {
              window.open(url, '_blank');
          }
      }
    });

    if (!this.acceptedFilesValue.includes("application/pdf")) {
      this.initializeSortable(); // Initialize sortable
    }
  }

  initializeSortable() {
    this.sortable = new Sortable(this.dropZone.element, {
      draggable: ".dz-preview",
      onEnd: () => {
        // Handle reorder event
        this.updateOrder();
      }
    });
  }

  async updateOrder() {
    const order = [];
    this.dropZone.element.querySelectorAll(".dz-preview").forEach((element, index) => {
      const file = this.dropZone.files.find(f => f.previewElement === element);
      if (file) {
        order.push({ id: file.upload && file.upload.uuid ? file.upload.uuid : file.name, position: index });
      }
    });

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const objectId = this.element.dataset.objectId; // Assurez-vous que l'élément contient l'ID de la propriété

    let url;
    if (this.picturePageValue === "true") {
      url = `/properties/${objectId}/pictures/update_order`;
    } else if (this.documentPageValue === "true") {
      url = `/properties/${objectId}/documents/update_order`;
    } else if (this.companyPageValue === "true") {
      url = `/companies/${objectId}/update_order`;
    } else if (this.purchaseNegociationPageValue === "true") {
      url = `/purchase_negociations/${objectId}/update_order_document`;
    }

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ order: order })
    });
  }

  async checkIfFileIsEncrypted(file) {
    let formData = new FormData();
    formData.append('file', file);

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch('/check_pdf_security', {
      method: 'POST',
      headers: { 'X-CSRF-Token': csrfToken },
      body: formData
    });
    const data = await response.json();
    return data.isSecure;
  }

  emitDropzoneError(file, errorMessage) {
    console.log("Erreur de téléchargement :", errorMessage);
    file.status = Dropzone.ERROR;
    this.dropZone.emit("error", file, errorMessage);
    this.dropZone.emit("complete", file);
  }

  displayExistingDocuments() {
    const sortedAttachments = this.existingDocumentsValue.sort((a, b) => a.position - b.position);

    sortedAttachments.forEach((attachment) => {
      const mockFile = {
        name: attachment.filename,
        size: attachment.byte_size,
        type: attachment.content_type,
        upload: { uuid: attachment.signed_id },
        url: attachment.url
      };

      this.dropZone.emit("addedfile", mockFile);
      this.dropZone.emit("thumbnail", mockFile, attachment.url);

      mockFile.accepted = true;
      mockFile.status = Dropzone.SUCCESS;
      this.dropZone.files.push(mockFile);

      this.createHiddenInputForExistingFile(attachment.signed_id, mockFile);

      const previewElement = mockFile.previewElement;
      const fileNameElement = previewElement.querySelector("[data-dz-name]");
      fileNameElement.setAttribute('data-url', attachment.url);

      if (attachment.content_type == "application/pdf") {
        const thumbnail = previewElement.querySelector(".dz-image img");
        if (thumbnail) {
          thumbnail.parentNode.removeChild(thumbnail);
        }
        previewElement.classList.remove("dz-image-preview");
        previewElement.classList.add("dz-file-preview", "dz-processing", "dz-complete");
      } else {
        previewElement.querySelector(".dz-progress").style.display = "none";
        previewElement.querySelector(".dz-size").textContent = "";
        const img = previewElement.querySelector(".dz-image > img");
        img.style.objectFit = "cover";
        img.style.width = "100%";
        img.style.height = "100%";
      }
    });

    // Mise à jour du nombre maximum de fichiers après l'ajout des fichiers existants
    // this.recalculateMaxFiles();
  }

  removeErrorMessages(event) {
    if (event.target.classList.contains('is-invalid')) {
      event.target.classList.remove('is-invalid');
      const errorMsg = event.target.parentNode.querySelector('.text-danger');
      if (errorMsg) errorMsg.remove();
    }
  }

  updateFileUploadedAttribute() {
    if (this.actionValue == 'property') return;

    const hasExistingFiles = this.dropZone.files.length > 0;
    this.element.setAttribute("data-file-uploaded-value", hasExistingFiles ? "true" : "false");
    const event = new CustomEvent('form:update');
    event.action_type = this.actionValue;
    document.dispatchEvent(event);
  }

  createHiddenInputForExistingFile(signedId, file) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.inputTarget.name;
    input.value = signedId; // Utilisez l'ID signé d'Active Storage
    this.element.appendChild(input); // Ajoutez l'input caché au formulaire
    file.hiddenInput = input;

    // Trouvez l'élément dropzoneInput
    const dropzoneInput = this.element.querySelector('.dropzone input[type="file"]');
    // Vérifiez si un fichier existe et ajustez l'attribut required
    if (dropzoneInput) {
      dropzoneInput.setAttribute('required', signedId ? "false" : "true");
    }
  }

  extractFileNameFromUrl(url) {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1];
  }

  hideFileInput() {
    this.inputTarget.style.display = "none";
    this.inputTarget.disabled = true;
  }

  // recalculateMaxFiles() {
  //   const initialMaxFiles = parseInt(this.data.get("maxFiles") || "1");
  //   const existingFilesCount = this.existingDocumentsValue.length;
  //   const newMaxFiles = initialMaxFiles - existingFilesCount;
  //   this.dropZone.options.maxFiles = Math.max(newMaxFiles, 0);
  // }

  // recalculateMaxFiles() {
  //   const initialMaxFiles = parseInt(this.data.get("maxFiles") || "1");
  //   const currentFilesCount = this.dropZone.files.length;
  //   const newMaxFiles = initialMaxFiles - currentFilesCount;
  //   this.dropZone.options.maxFiles = Math.max(newMaxFiles, 0);
  // }

  // deleteFileFromServer(file) {
  //   if (file.publicId || file.upload.uuid) {
  //     const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  //     const id = file.publicId || file.upload.uuid;
  //     fetch(`/destroy_cloudinary`, {
  //       method: 'DELETE',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'X-CSRF-Token': csrfToken
  //       },
  //       body: JSON.stringify({ id: id })
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.success) {
  //         console.log(`Fichier a été supprimé avec succès de Cloudinary.`);
  //       } else {
  //         console.error(`Erreur lors de la suppression du fichier dans Cloudinary.`);
  //       }
  //     })
  //     .catch(error => {
  //       console.error(`Erreur réseau lors de la tentative de suppression du fichier :`, error);
  //     });
  //   }
  // }

  bindEvents() {
    let isSubmitting = false;

    // Détecter la soumission du formulaire
    this.form.addEventListener('submit', () => {
      isSubmitting = true;
    });

    this.dropZone.on("addedfile", (file) => {
      setTimeout(async () => {
        // Vérifie si le type MIME du fichier correspond à acceptedFilesValue
        const acceptedTypes = this.acceptedFilesValue.split(","); // Accepte des types multiples si nécessaire
        const isAcceptedType = acceptedTypes.some((type) => {
          if (type === "image/*") {
            return file.type.startsWith("image/");
          }
          return file.type === type;
        });

        if (!isAcceptedType) {
          // Si le type du fichier n'est pas accepté, afficher une erreur
          this.emitDropzoneError(
            file,
            i18n.t("dropzone.invalid_file_type", { type: file.type })
          );
          return;
        }

        if (file.type === "application/pdf" && file.status === "added") {
          // Check if the PDF is secure
          const isSecure = await this.checkIfFileIsEncrypted(file);
          if (isSecure) {
            // If the PDF is secure, display an error
            this.emitDropzoneError(file, i18n.t('dropzone.file_is_encrypted'));
          } else {
            file.accepted && createDirectUploadController(this, file).start();
          }
        } else if (file instanceof Blob) {
          // For other file types, proceed with direct upload
          file.accepted && createDirectUploadController(this, file).start();
        }
      }, 500);

      // Customize PDF file appearance
      if (file.type === "application/pdf") {
        const previewElement = file.previewElement.querySelector(".dz-image");
        previewElement.insertAdjacentHTML("afterbegin", "<i class='fa fa-file-pdf fa-2x'></i>");
        previewElement.classList.add('dz-thumbnail-custom');
      }

      this.updateFileUploadedAttribute();
      // this.recalculateMaxFiles();
    });

    this.dropZone.on("removedfile", (file) => {
      if (isSubmitting) {
        console.log("Suppression de fichier ignorée pendant la soumission.");
        return; // Empêche la suppression si le formulaire est en cours de soumission
      }

      if (file.hiddenInput) {
        file.hiddenInput.parentNode.removeChild(file.hiddenInput);
      }
      file.controller && removeElement(file.controller.hiddenInput);

      // Envoyer une requête au serveur pour supprimer le fichier de Cloudinary
      // this.deleteFileFromServer(file);

      this.updateFileUploadedAttribute();
      // this.recalculateMaxFiles();
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("processing", (file) => {
      if (this.submitButton) {
        this.submitButton.disabled = true;
      }
    });

    this.dropZone.on("queuecomplete", (file) => {
      if (this.submitButton) {
        this.submitButton.disabled = false;
      }
    });

    this.dropZone.on('success', (file) => {
      // Ciblez les éléments nécessaires pour supprimer les messages d'erreur
      const inputsWithError = this.element.querySelectorAll('.is-invalid');
      inputsWithError.forEach(input => {
        this.removeErrorMessages({ target: input });
      });
    });

    this.dropZone.on('error', (file, response) => {});
  }

  setupFormErrorListener() {
    this.form.addEventListener('turbo:submit-end', (event) => {
      const { success, fetchResponse } = event.detail;

      if (!success) {
        const clonedResponse = fetchResponse.response.clone();
        clonedResponse.json().then(data => {
          if (data.approved === false) {
            if (data.message) {
              // Afficher le message global dans un container flash déjà présent
              // Par exemple, si vous avez un <div id="flash-container"></div> dans votre layout
              const flashContainer = document.getElementById('flash-messages-container');
              if (flashContainer) {
                flashContainer.innerHTML = `<div class="alert alert-danger">${data.message}</div>`;
              }
            }

            if (Array.isArray(data.errors)) {
              data.errors.forEach((fileError) => {
                // Récupérer attachment_name depuis la réponse JSON
                // Il faut s'assurer que l'objet renvoyé par le serveur contient 'attachment_name'
                const { id: signedId, errors: rawErrors, attachment_name } = fileError;
                // Vérifier si l'attachment_name correspond à la dropzone courante
                if (attachment_name !== this.documentNameValue) {
                  // Si ça ne correspond pas, on ignore cette erreur dans cette dropzone
                  return;
                }

                const translatedErrors = fileError.errors.map((errorStr) => {
                  const sanitizedKey = errorStr.trim().toLowerCase().replace(/[^0-9a-z]/gi, '_');
                  return i18n.t(`cloudinary.errors.${sanitizedKey}`, { defaultValue: errorStr });
                });

                const joinedErrors = translatedErrors.join(", ");
                const errorMessage = i18n.t('dropzone.moderation_failed', { errors: joinedErrors });

                const matchingFile = this.dropZone.files.find(file => file.signedId === signedId);

                if (matchingFile) {
                  this.dropZone.emit('error', matchingFile, errorMessage);
                } else {
                  console.log(`Aucun fichier trouvé pour l'id: ${signedId}`);
                }
              });
            }
          }
        }).catch((e) => {
          console.log("Impossible de parser la réponse JSON d'erreur.", e);
        });
      }
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }
  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }
  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }
  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }
  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }
  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
  get uploadMultiple() {
    return this.data.get("uploadMultiple") || false;
  }
  get form() {
    return this.element.closest("form");
  }
  get submitButton() {
    return findElement(this.form, "input[type=submit], button[type=submit]");
  }
  get removeFileLabel() {
    return i18n.t('dropzone.remove_file');
  }
  get cancelUploadLabel() {
    return i18n.t('dropzone.cancel_upload');
  }
  get uploadCanceledLabel() {
    return i18n.t('dropzone.upload-canceled');
  }
  get cancelUploadConfirmationLabel() {
    return i18n.t('dropzone.cancel_upload_confirmation');
  }
  get fileTooBigLabel() {
    return this.fileTooBigLabelValue;
  }
  get maxFilesExceededLabel() {
    return i18n.t('dropzone.max_files_exceeded', { max_files: this.maxFiles });
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }
  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.file.publicId = attributes.key;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    // Associer le signed_id au file
    this.file.signedId = this.hiddenInput.value;
    // Emettre les événements dropzone
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  let dropzone = new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    uploadMultiple: controller.uploadMultiple,
    autoQueue: false,
    // autoProcessQueue: false,
    dictRemoveFile: controller.removeFileLabel,
    dictCancelUpload: controller.cancelUploadLabel,
    dictUploadCanceled: controller.uploadCanceledLabel,
    dictCancelUploadConfirmation: controller.cancelUploadConfirmationLabel,
    dictFileTooBig: controller.fileTooBigLabel,
    dictMaxFilesExceeded: controller.maxFilesExceededLabel
  });

  return dropzone;
}
