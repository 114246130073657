import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="refresh-page"
export default class extends Controller {
  connect() {
    // Écoute l'événement "pageshow" pour détecter le retour
    window.addEventListener("pageshow", this.handlePageShow.bind(this));
  }

  disconnect() {
    // Nettoie l'écouteur pour éviter les fuites de mémoire
    window.removeEventListener("pageshow", this.handlePageShow.bind(this));
  }

  handlePageShow(event) {
    if (event.persisted) {
      window.location.reload();
    }
  }
}
