import { Controller } from "@hotwired/stimulus";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

// Connects to data-controller="address-autocomplete"
export default class extends Controller {
  static values = { apiKey: String, address: String };
  static targets = ["address"];

  connect() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      types: "place,address", // Limit types for better results
      countries: "fr", // Restrict to France
    });
    this.geocoder.addTo(this.element);
    this.geocoder._inputEl.value = this.addressValue;

    // Track each request made to the Mapbox API
    this.geocoder.on("results", (event) => {
      this.#trackGeocodingUsage();
    });

    // Handle geocoder result event (final selection)
    this.geocoder.on("result", (event) => {
      this.#setInputValue(event);
    });

    // Handle geocoder clear event
    this.geocoder.on("clear", () => this.#clearInputValue());
  }

  #trackGeocodingUsage() {
    // Send a POST request to log the usage of the geocoding API
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    fetch('/application_costs/geocoder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      body: JSON.stringify({ usage: 'geocoder' }), // Optional payload
    })
      .then((response) => {
        if (!response.ok) {
          console.error('Erreur lors de l\'enregistrement de l\'utilisation du geocoder');
        }
      })
      .catch((error) => {
        console.error('Erreur réseau :', error);
      });
  }

  #setInputValue(event) {
    this.addressTarget.value = event.result["place_name"];
  }

  #clearInputValue() {
    this.addressTarget.value = "";
  }

  disconnect() {
    this.geocoder.onRemove();
  }
}
