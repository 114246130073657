import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxgl from "mapbox-gl"

// Connects to data-controller="map-with-name"
export default class extends Controller {
  // static values = {
  //   apiKey: String,
  //   markers: Array
  // }

  connect() {
    // mapboxgl.accessToken = this.apiKeyValue

    // this.map = new mapboxgl.Map({
    //   container: this.element,
    //   style: "mapbox://styles/mapbox/streets-v10"
    // })
    // this.#addMarkersToMap();
    // this.#fitMapToMarkers();
    // this.#mouseToCard();

    // this.map.addControl(new MapboxGeocoder({
    //   accessToken: mapboxgl.accessToken,
    //   mapboxgl: mapboxgl }));

    // // Add zoom and rotation controls to the map.
    // this.map.addControl(new mapboxgl.NavigationControl());

  }

  // #addMarkersToMap() {
  //   this.markersValue.forEach((marker) => {
  //     // Create a HTML element for your custom marker
  //     const customMarker = document.createElement("div")
  //     customMarker.innerHTML = marker.marker_html
  //     // Pass the element as an argument to the new marker
  //     new mapboxgl.Marker(customMarker)
  //       .setLngLat([marker.lng, marker.lat])
  //       .addTo(this.map)
  //   })
  // }

  // #fitMapToMarkers() {
  //   const bounds = new mapboxgl.LngLatBounds()
  //   this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
  //   this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
  // }

  // #mouseToCard() {
  //   var cards = document.querySelectorAll('.card-company');

  //   cards.forEach(function (card) {
  //     card.addEventListener('mouseenter', function () {
  //       var companyId = card.dataset.id;
  //       var defaultMarker = document.getElementById('default-marker-'+companyId)
  //       var nameMarker = document.getElementById('name-marker-'+companyId)
  //       defaultMarker.classList.add('d-none');
  //       nameMarker.classList.remove('d-none');
  //     });

  //     card.addEventListener('mouseleave', function () {
  //       var companyId = card.dataset.id;
  //       var defaultMarker = document.getElementById('default-marker-'+companyId)
  //       var nameMarker = document.getElementById('name-marker-'+companyId)
  //       defaultMarker.classList.remove('d-none');
  //       nameMarker.classList.add('d-none');
  //     });
  //   });
  // }
}
