import { Controller } from "@hotwired/stimulus";
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch from 'instantsearch.js';
import { refinementList, infiniteHits, configure, panel } from 'instantsearch.js/es/widgets';
import { connectSortBy, connectCurrentRefinements, connectStats, connectRange, connectGeoSearch, connectClearRefinements } from 'instantsearch.js/es/connectors';
import { history } from 'instantsearch.js/es/lib/routers';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from 'mapbox-gl';
import i18n from "../helpers/i18n_config";
import Swiper from 'swiper/bundle';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';
import AutoNumeric from "autonumeric";

let map = null;
let markers = [];
let isMobileOrTablet = window.innerWidth <= 992;

export default class extends Controller {
  static targets = [
    "cards",
    "flipCard",
    "defaultMarker",
    "priceMarker",
    "map",
    "indexPropertyMenu"
  ]

  static values = {
    mapboxApiKey: String,
    url: String,
    apiKey: String,
    loanRate: Number,
    repaymentPeriod: Number,
    contributionAmount: Number,
    language: String,
    isUserLoggedIn: Boolean,
    isInventory: Boolean,
    isBuy: Boolean,
    isRent: Boolean,
    isInventoryCompany: Boolean,
    propertyId: Number,
    displayIds: Array,
    notDisplayIds: Array,
    propertyLimit: Number
  }

  isMapOpen = false;

  connect() {
    let filtersArray = [];
    let filtersString = '';
    const urlParams = new URLSearchParams(window.location.search);
    const propertyIds = urlParams.get('property_ids'); // Exemple: "1,2,3"

    if (propertyIds) {
      // Nettoyage et ajout des IDs dans les filtres
      const cleanedIds = propertyIds.split(',')
                                    .map(id => id.trim())
                                    .filter(id => !isNaN(parseInt(id, 10))); // Conversion sécurisée en entier
      if (cleanedIds.length > 0) {
        filtersArray.push(`id IN [${cleanedIds.join(',')}]`);
      }
    } else if (this.isInventoryValue) {
      // Gestion des propriétés à afficher si inventory est actif
      if (this.hasDisplayIdsValue && Array.isArray(this.displayIdsValue) && this.displayIdsValue.length > 0) {
        filtersArray.push(`id IN [${this.displayIdsValue.join(',')}]`);
      } else {
        // Aucun ID à afficher
        filtersArray.push("id = 0");
      }
    } else {
      // Gestion des propriétés à exclure
      if (this.hasNotDisplayIdsValue && Array.isArray(this.notDisplayIdsValue) && this.notDisplayIdsValue.length > 0) {
        filtersArray.push(this.notDisplayIdsValue.map(id => `NOT id = ${id}`).join(' AND '));
      }

      // Ajout des propriétés prêtes à être mises en ligne
      filtersArray.push("ready_to_go_online = true", "on_line = true");

      // Ajout des filtres en fonction du type de transaction
      if (this.isBuyValue) {
        filtersArray.push('object = "sell"');
      }

      if (this.isRentValue) {
        filtersArray.push('object = "rent"');
      }
    }

    // Conversion des filtres en une seule chaîne
    filtersString = filtersArray.join(' AND ');

    mapboxgl.accessToken = this.mapboxApiKeyValue

    const url = this.urlValue;
    const apiKey = this.apiKeyValue;

    const customGeoSearch = connectGeoSearch(this.renderGeoSearch);
    const customSortBy = connectSortBy(this.renderSortBy);
    const customCurrentRefinements = connectCurrentRefinements(this.renderCurrentRefinements);
    const customStats = connectStats(this.renderStats);
    const customClearRefinements = connectClearRefinements(this.renderClearRefinements);
    const customTitleStats = connectStats(this.renderTitleStats);
    const customRangeCurrencyInput = connectRange((renderOptions, isFirstRender) => {
      const customRangeCurrencyInputContainer = document.querySelector('#total-price-range-input');
      this.renderRangeInput(renderOptions, isFirstRender, '€', "999999999", customRangeCurrencyInputContainer)
    });
    const customRangeAreaInput = connectRange((renderOptions, isFirstRender) => {
      const customRangeAreaContainer = document.querySelector('#area-range-input');
      this.renderRangeInput(renderOptions, isFirstRender, i18n.t("simple_form.square_meter_html"), "99999", customRangeAreaContainer);
    });

    const searchClient = instantMeiliSearch(url, apiKey);
    this.meiliSearchClient = searchClient;

    const search = instantsearch({
      indexName: 'Property',
      searchClient,
      routing: {
        router: history({
          cleanUrlOnDispose: false,
        }),
      },
      future: {
        preserveSharedStateOnUnmount: true,
      },
    });

    const typePropertyRefinementList = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.property_type_filter")}</h5>
        `,
      },
    })(refinementList);

    const objectPropertyRefinementList = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.property_object_filter")}</h5>
        `,
      },
    })(refinementList);

    const areaRangeInput = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.total_area_filter_html")}</h5>
        `,
      },
      hidden(options) {
        // Vérifie si les statistiques pour la plage sont valides
        if (!options.results) return true;

        const stats = options.results.getFacetStats('description.total_area');
        return !stats || stats.min === stats.max; // Masque si la plage n'est pas exploitable
      },
    })(customRangeAreaInput);

    const totalPriceRangeInput = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.total_price_including_charges_filter")}</h5>
        `,
      },
      hidden(options) {
        // Vérifie si les statistiques pour la plage sont valides
        if (!options.results) return true;

        const stats = options.results.getFacetStats('condition.total_price_including_charges');
        return !stats || stats.min === stats.max; // Masque si la plage n'est pas exploitable
      },
    })(customRangeCurrencyInput);

    const mainRoomRefinementList = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.main_rooms_filter")}</h5>
        `,
      },
      hidden(options) {
        // Si aucun filtre n'est disponible, le panel sera masqué
        return options.results.getFacetValues('description.main_room_number').length === 0;
      },
    })(refinementList);

    const bedroomRefinementList = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.bedroom_filter")}</h5>
        `,
      },
      hidden(options) {
        // Si aucun filtre n'est disponible, le panel sera masqué
        return options.results.getFacetValues('description.bedroom_number').length === 0;
      },
    })(refinementList);

    const bathroomRefinementList = panel({
      templates: {
        header: (renderOptions) => `
          <h5>${i18n.t("properties.index.bathroom_filter")}</h5>
        `,
      },
      hidden(options) {
        // Si aucun filtre n'est disponible, le panel sera masqué
        return options.results.getFacetValues('description.bathroom_number').length === 0;
      },
    })(refinementList);

    search.addWidgets([

      configure({
        filters: filtersString,
        hitsPerPage: 1000,
      }),

      customCurrentRefinements({
        container: document.querySelector('#current-refinements'),
      }),

      customStats({
        container: document.querySelector('#stats'),
      }),

      customTitleStats({
        container: document.querySelector('#title-stats'),
      }),

      customClearRefinements({
        container: '#clear-refinements',
      }),

      typePropertyRefinementList({
        container: '#type-property-refinement-list',
        attribute: 'type_property',
        templates: {
          item: (item) => {
            // Ajouter l'attribut `disabled` dans l'input si nécessaire
            const isChecked = item.isRefined ? 'checked' : '';
            const isDisabled = item.disabled ? 'disabled' : '';
            const label = i18n.t(`activerecord.attributes.property.type_properties.${item.value}`);
            const count = item.count;

            return `
              <label class="ais-RefinementList-label" style="display: inline-flex; align-items: center; cursor: ${item.disabled ? 'not-allowed' : 'pointer'};">
                <input
                  class="ais-RefinementList-checkbox"
                  type="checkbox"
                  value="${item.value}"
                  ${isChecked}
                  ${isDisabled}
                >
                <span class="ais-RefinementList-labelText">${label}</span>
                <span class="ais-RefinementList-count">${count}</span>
              </label>
            `;
          },
        },
        transformItems(items) {
          // Ajouter une logique pour désactiver dynamiquement les items
          const refinedItem = items.find((item) => item.isRefined);

          if (refinedItem) {
            if (refinedItem.value === 'land') {
              // Si "land" est sélectionné, désactiver tous les autres
              return items.map((item) => ({
                ...item,
                disabled: item.value !== 'land',
              }));
            } else {
              // Si un autre filtre est sélectionné, désactiver "land"
              return items.map((item) => ({
                ...item,
                disabled: item.value === 'land',
              }));
            }
          }

          // Aucun filtre sélectionné, tous les éléments sont actifs
          return items.map((item) => ({
            ...item,
            disabled: false,
          }));
        },
      }),

      totalPriceRangeInput({
        container: '#total-price-range-input',
        attribute: 'condition.total_price_including_charges',
      }),

      areaRangeInput({
        container: '#area-range-input',
        attribute: 'description.total_area',
      }),

      mainRoomRefinementList({
        container: '#main-room-refinement-list',
        attribute: 'description.main_room_number',
        limit: 5,
        showMore: true,
        sortBy: ['main_room_number:asc'],
        templates: {
          showMoreText: (data) => data.isShowingMore ? i18n.t('meilisearch.see_less') : i18n.t('meilisearch.see_more')
        },
      }),

      bedroomRefinementList({
        container: '#bedroom-refinement-list',
        attribute: 'description.bedroom_number',
        limit: 3,
        showMore: true,
        sortBy: ['bedroom_number:asc'],
        templates: {
          showMoreText: (data) => data.isShowingMore ? i18n.t('meilisearch.see_less') : i18n.t('meilisearch.see_more'),
        },
      }),

      bathroomRefinementList({
        container: '#bathroom-refinement-list',
        attribute: 'description.bathroom_number',
        limit: 3,
        showMore: true,
        sortBy: ['bathroom_number:asc'],
        templates: {
          showMoreText: (data) => data.isShowingMore ? i18n.t('meilisearch.see_less') : i18n.t('meilisearch.see_more'),
        },
      }),

      customGeoSearch(items => renderGeoSearch(items)),

      infiniteHits({
        container: "#infinite-hits",
        templates: {
          item: (item) => this.renderProperty(item), // Utilisation existante pour générer des éléments

          loadMore: () =>
            `
            <button class="btn btn-primary w-100 mt-3">
              ${i18n.t('meilisearch.see_more')}
            </button>
            `,

          empty: () =>
            `
            <div>
              <p class="border rounded border-danger text-danger p-3 my-3">${i18n.t(
                'meilisearch.no_result'
              )}</p>
              ${
                this.isInventoryValue || this.isInventoryCompanyValue
                  ? `
                      <div class="text-end">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onclick="window.location.href='/properties/new'"
                        >
                          ${i18n.t(
                            'dashboards.inventory.add_new_real_estate_label'
                          )}
                        </button>
                      </div>
                    `
                  : ''
              }
              <div class="text-center mt-4">
                <img
                  src="https://res.cloudinary.com/dqicgjooj/image/upload/v1706777214/Configuration/eprnzg3w0h4rjy3el6wv.png"
                  class="w-100"
                  alt="Add New Property"
                />
              </div>
            </div>
            `,
        },
      }),
    ]);

    if (this.isBuyValue) {
      search.addWidgets([
        customSortBy({
          container: '#sort-by',
          items: [
            { label: i18n.t("meilisearch.default"), value: 'Property' },
            { label: i18n.t("meilisearch.most_recent"), value: 'Property:updated_at:desc' },
            { label: i18n.t("meilisearch.oldest"), value: 'Property:updated_at:asc' },
            { label: i18n.t("meilisearch.increasing_price"), value: 'Property:condition.total_price_including_charges:asc' },
            { label: i18n.t("meilisearch.decreasing_price"), value: 'Property:condition.total_price_including_charges:desc' },
            { label: i18n.t("meilisearch.increasing_price_per_surface_area"), value: 'Property:condition.price_per_meter:asc' },
            { label: i18n.t("meilisearch.decreasing_price_per_surface_area"), value: 'Property:condition.price_per_meter:desc' },
            { label: i18n.t("meilisearch.biggest"), value: 'Property:description.total_area:desc' },
            { label: i18n.t("meilisearch.smallest"), value: 'Property:description.total_area:asc' },
          ],
        }),
      ]);
    } else if (this.isRentValue) {
      search.addWidgets([
        customSortBy({
          container: '#sort-by',
          items: [
            { label: i18n.t("meilisearch.default"), value: 'Property' }, // Index par défaut
            { label: i18n.t("meilisearch.most_recent"), value: 'Property:updated_at:desc' },
            { label: i18n.t("meilisearch.oldest"), value: 'Property:updated_at:asc' },
            { label: i18n.t("meilisearch.increasing_price"), value: 'Property:condition.total_price_including_charges:asc' },
            { label: i18n.t("meilisearch.decreasing_price"), value: 'Property:condition.total_price_including_charges:desc' },
            { label: i18n.t("meilisearch.increasing_price_per_surface_area"), value: 'Property:condition.price_per_meter:asc' },
            { label: i18n.t("meilisearch.decreasing_price_per_surface_area"), value: 'Property:condition.price_per_meter:desc' },
            { label: i18n.t("meilisearch.biggest"), value: 'Property:description.total_area:desc' },
            { label: i18n.t("meilisearch.smallest"), value: 'Property:description.total_area:asc' },
          ],
        }),
      ]);
    } else {
      search.addWidgets([
        objectPropertyRefinementList({
          container: '#object-property-refinement-list',
          attribute: 'object',
          templates: {
            item: (item) => {
              // Ajouter l'attribut `disabled` dans l'input si nécessaire
              const isChecked = item.isRefined ? 'checked' : '';
              const isDisabled = item.disabled ? 'disabled' : '';
              const label = i18n.t(`activerecord.attributes.property.objects.${item.value}`);
              const count = item.count;

              return `
                <label class="ais-RefinementList-label" style="display: inline-flex; align-items: center; cursor: ${item.disabled ? 'not-allowed' : 'pointer'};">
                  <input
                    class="ais-RefinementList-checkbox"
                    type="checkbox"
                    value="${item.value}"
                    ${isChecked}
                    ${isDisabled}
                  >
                  <span class="ais-RefinementList-labelText">${label}</span>
                  <span class="ais-RefinementList-count">${count}</span>
                </label>
              `;
            },
          },
        }),
        customSortBy({
          container: '#sort-by',
          items: [
            { label: i18n.t("meilisearch.default"), value: 'Property' }, // Index par défaut
            { label: i18n.t("meilisearch.most_recent"), value: 'Property:updated_at:desc' },
            { label: i18n.t("meilisearch.oldest"), value: 'Property:updated_at:asc' },
            { label: i18n.t("meilisearch.increasing_price_per_surface_area"), value: 'Property:condition.price_per_meter:asc' },
            { label: i18n.t("meilisearch.decreasing_price_per_surface_area"), value: 'Property:condition.price_per_meter:desc' },
            { label: i18n.t("meilisearch.biggest"), value: 'Property:description.total_area:desc' },
            { label: i18n.t("meilisearch.smallest"), value: 'Property:description.total_area:asc' },
          ],
        }),
      ]);
    }

    search.start();
    search.on('render', () => {
      // le'utilisateur est sur cette page http://localhost:3000/properties/buy_index
      if (window.location.href.includes('/properties/buy_index')) {
        this.restoreMeta("meta.titles.properties_buy_index", "meta.descriptions.properties_buy_index", "meta.keywords.properties_buy_index"); // Initialiser les meta
      } else if (window.location.href.includes('/properties/rent_index')) {
        this.restoreMeta("meta.titles.properties_rent_index", "meta.descriptions.properties_rent_index", "meta.keywords.properties_rent_index"); // Initialiser les meta
      }

    });

    this.search = search;

    document.querySelector('#clear-refinements').addEventListener('click', () => {
      const minInputs = document.querySelectorAll('input[name="min"]');
      const maxInputs = document.querySelectorAll('input[name="max"]');

      minInputs.forEach((input) => {
        const autoNumericInstance = AutoNumeric.getAutoNumericElement(input); // Récupérer l'instance
        if (autoNumericInstance) {
          autoNumericInstance.set(''); // Réinitialiser avec AutoNumeric
        } else {
          input.value = ''; // Fallback si AutoNumeric n'est pas activé
        }
      });

      maxInputs.forEach((input) => {
        const autoNumericInstance = AutoNumeric.getAutoNumericElement(input); // Récupérer l'instance
        if (autoNumericInstance) {
          autoNumericInstance.set(''); // Réinitialiser avec AutoNumeric
        } else {
          input.value = ''; // Fallback si AutoNumeric n'est pas activé
        }
      });
    });
  }

  disconnect() {
    if (this.context) {
      this.context = null; // Supprimer la référence au contexte
    }

    // Nettoyer l'instance de recherche (Meilisearch ou Algolia)
    if (this.search) {
      try {
        if (this.search.dispose) {
          this.search.dispose();
        }
      } catch (error) {
        console.error("Erreur lors de la destruction de search :", error);
      }
      this.search = null;
    }

    // Nettoyer la map
    if (map) {
      try {
        map.remove();
      } catch (error) {
        console.error("Erreur lors de la destruction de la map :", error);
      }
      map = null;
    }
  }

  async restoreMeta(titleKey, descriptionKey, keywordsKey, propertyId) {
    let nbHits = 0;
    let nbApartments = 0;
    let nbHouses = 0;
    let nbLands = 0;
    let minPrice = 0;
    let maxPrice = 0;
    let minTotalArea = 0;
    let maxTotalArea = 0;
    let minMainRoomNumber = 0;
    let maxMainRoomNumber = 0;
    let minBedroomNumber = 0;
    let maxBedroomNumber = 0;
    let minBathroomNumber = 0;
    let maxBathroomNumber = 0;
    let typeProperty = "";
    let zipcode = "";
    let city = "";
    let price = 0;
    let TotalArea = 0;
    let mainRoomNumber = 0;
    let bedroomNumber = 0;
    let bathroomNumber = 0;

    if (propertyId) {
      try {
        const property = await this.findPropertyById(propertyId);
        if (property) {
          // Exemple d'utilisation de la propriété trouvée
          typeProperty = this.#formatTypeAndObject(property);
          zipcode = property.zipcode;
          city = property.city;
          price = property.condition.total_price_including_charges;
          livingSpaceArea = property.description.total_area;
          mainRoomNumber = property.description.main_room_number;
          bedroomNumber = property.description.bedroom_number;
          bathroomNumber = property.description.bathroom_number;

          if (property.photos && property.photos.length > 0) {
            let imageUrl = property.photos[0]; // Assurez-vous que l'URL est complète
            let metaOgImage = document.querySelector("meta[property='og:image']");
            if (metaOgImage) {
              metaOgImage.setAttribute("content", imageUrl);
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la propriété :", error);
      }
    } else {
      let imageUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1706777207/Configuration/g4ltxpss6vyki0fhjtoz.png"; // Assurez-vous que l'URL est complète
      let metaOgImage = document.querySelector("meta[property='og:image']");
      if (metaOgImage) {
        metaOgImage.setAttribute("content", imageUrl);
      }
    }

    if (this.search && this.search.helper && this.search.helper.lastResults) {
      const searchResults = this.search.helper.lastResults;
      nbHits = searchResults ? searchResults.nbHits : 0;

      // Récupération des données de facette directement depuis les résultats bruts
      let facets = searchResults.disjunctiveFacets || [];

      // Fonction pour obtenir les statistiques de la facette de manière sécurisée
      const getFacetData = (facetName, key) => {
        const facet = facets.find(facet => facet.name === facetName);
        return facet && facet.data && key in facet.data ? facet.data[key] : 0;
      };

      // Utilisation de la fonction pour obtenir les données des facettes
      nbApartments = getFacetData('type_property', 'apartment');
      nbHouses = getFacetData('type_property', 'house');
      nbLands = getFacetData('type_property', 'land');

      const totalPriceFacet = facets.find(facet => facet.name === 'condition.total_price_including_charges');
      minPrice = totalPriceFacet && totalPriceFacet.stats ? totalPriceFacet.stats.min || 0 : 0;
      maxPrice = totalPriceFacet && totalPriceFacet.stats ? totalPriceFacet.stats.max || 0 : 0;

      const totalAreaFacet = facets.find(facet => facet.name === 'description.total_area');
      minTotalArea = totalAreaFacet && totalAreaFacet.stats ? totalAreaFacet.stats.min || 0 : 0;
      maxTotalArea = totalAreaFacet && totalAreaFacet.stats ? totalAreaFacet.stats.max || 0 : 0;

      const mainRoomNumberFacet = facets.find(facet => facet.name === 'description.main_room_number');
      minMainRoomNumber = mainRoomNumberFacet && mainRoomNumberFacet.stats ? mainRoomNumberFacet.stats.min || 0 : 0;
      maxMainRoomNumber = mainRoomNumberFacet && mainRoomNumberFacet.stats ? mainRoomNumberFacet.stats.max || 0 : 0;

      const bedroomNumberFacet = facets.find(facet => facet.name === 'description.bedroom_number');
      minBedroomNumber = bedroomNumberFacet && bedroomNumberFacet.stats ? bedroomNumberFacet.stats.min || 0 : 0;
      maxBedroomNumber = bedroomNumberFacet && bedroomNumberFacet.stats ? bedroomNumberFacet.stats.max || 0 : 0;

      const bathroomNumberFacet = facets.find(facet => facet.name === 'description.bathroom_number');
      minBathroomNumber = bathroomNumberFacet && bathroomNumberFacet.stats ? bathroomNumberFacet.stats.min || 0 : 0;
      maxBathroomNumber = bathroomNumberFacet && bathroomNumberFacet.stats ? bathroomNumberFacet.stats.max || 0 : 0;
    }

    if (titleKey) {
      // Utiliser `nbHits` pour afficher le nombre de résultats dans le titre si fourni
      const title = nbHits ?
        i18n.t(
          titleKey, {
            count: nbHits,
            nb_apartments: nbApartments,
            nb_houses: nbHouses,
            nb_lands: nbLands,
            type_property: typeProperty,
            city: city,
            zipcode: zipcode,
            price: price
          }
        )
      :
      i18n.t(titleKey);

      document.title = title;
      const metaOgTitle = document.querySelector("meta[property='og:title']");
      if (metaOgTitle) {
        metaOgTitle.setAttribute("content", title);
      }
    }

    if (descriptionKey) {
      const description = nbHits ?
        i18n.t(
          descriptionKey, {
            count: nbHits,
            nb_apartments: nbApartments,
            nb_houses: nbHouses,
            nb_lands: nbLands,
            min_price: minPrice,
            max_price: maxPrice,
            min_total_area: minTotalArea,
            max_total_area: maxTotalArea,
            min_main_room_number: minMainRoomNumber,
            max_main_room_number: maxMainRoomNumber,
            min_bedroom_number: minBedroomNumber,
            max_bedroom_number: maxBedroomNumber,
            min_bathroom_number: minBathroomNumber,
            max_bathroom_number: maxBathroomNumber,
            type_property: typeProperty,
            city: city,
            zipcode: zipcode,
            price: price,
            total_area: TotalArea,
            main_room_number: mainRoomNumber,
            bedroom_number: bedroomNumber,
            bathroom_number: bathroomNumber,
          }
        )
        :
        i18n.t(titleKey);

      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
      const metaOgDescription = document.querySelector("meta[property='og:description']");
      if (metaOgDescription) {
        metaOgDescription.setAttribute("content", description);
      }

      // Lire l'URL actuelle de la page
      const currentUrl = window.location.href;

      // Sélectionner la balise meta 'og:url' et mettre à jour son contenu
      const metaOgUrl = document.querySelector("meta[property='og:url']");
      if (metaOgUrl) {
        metaOgUrl.setAttribute("content", currentUrl);
      }
    }

    if (keywordsKey) {
      const keywords = i18n.t(keywordsKey)
      const metaKeywords = document.querySelector("meta[name='keywords']");
      if (metaKeywords) {
        metaKeywords.setAttribute("content", keywords);
      }
    }
  }

  preventDefaultTouch(event) {
    let isInImagesContainer = event.target.closest('.images-container');
    if (!isInImagesContainer) {
      event.preventDefault();
    }
  }

  hiddenVisibiltyTurboRender() {
    document.body.classList.add('no-scroll');

    // Centraliser les éléments
    this.scrollBlockElements = [
      { element: this.hasFlipCardTarget ? this.flipCardTarget : null, classes: ['no-scroll', 'hidden-no-scroll'] },
      { element: this.hasIndexPropertyMenuTarget ? this.indexPropertyMenuTarget : null, classes: ['no-scroll'] },
      { element: document.querySelector('.top-navbar'), classes: ['hidden-no-scroll', 'no-scroll'] },
      { element: document.querySelector('.bottomNavbar'), classes: ['hidden-no-scroll', 'no-scroll'] },
      { element: document.querySelector('.notification-container'), classes: ['d-none'] }
    ];

    // Ajouter classes et écouteurs
    this.scrollBlockElements.forEach(({ element, classes }) => {
      if (element) {
        element.classList.add(...classes);
        element.addEventListener('touchmove', this.preventDefaultTouch, { passive: false }); // Utiliser seulement ici
      }
    });
  }

  restoreVisibilityAfterTurboRender() {
    document.body.classList.remove('no-scroll');

    // Supprimer classes et écouteurs
    this.scrollBlockElements?.forEach(({ element, classes }) => {
      if (element) {
        element.classList.remove(...classes);
        element.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }
    });

    // Nettoyer la liste pour éviter les références circulaires
    this.scrollBlockElements = null;
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openMap() {
    this.mapTarget.classList.add("map-container");
    this.mapTarget.style.display = "block";
    this.mapTarget.classList.remove("hidden-no-scroll", 'hidden');
    this.isMapOpen = true; // Mettez à jour l'état lorsque la map est ouverte
    this.hiddenVisibiltyTurboRender();

    requestAnimationFrame(() => {
      if (map) {
        // Ajoutez ici l'écouteur d'événements pour les clics sur la carte
        map.on('click', (e) => {
          if (!e.originalEvent.target.closest('.mapboxgl-marker')) {
            // Si l'utilisateur n'a pas cliqué sur un marqueur, fermez la carte de détails de propriété
            this.closePropertyCard();
          }
        });

        if (markers.length > 0) {
          const bounds = new mapboxgl.LngLatBounds();

          markers.forEach(marker => {
            bounds.extend(marker.getLngLat());
          });
          map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
        }

        // Forcer le redimensionnement après que la carte soit affichée
        map.resize();
      }
    });
  }

  closePropertyCard() {
    // Cachez la carte de détails de propriété
    const propertyDetails = document.getElementById('propertyMobileModal');
    if (propertyDetails) {
      propertyDetails.style.display = 'none';
    }

    // Réajustez la taille de la carte pour occuper toute la page
    const mapContainer = document.getElementById('property-index-map');
    if (mapContainer) {
      mapContainer.style.height = '100vh'; // Ou toute autre logique de dimensionnement que vous préférez
    }

    // Assurez-vous d'appeler map.resize() pour que Mapbox GL JS ajuste la carte à la nouvelle taille du conteneur
    if (map) map.resize();

    let container = document.getElementById('propertyDetailsContent');
    container.innerHTML = ''; // Nettoyage du contenu précédent
  }

  closeMap() {
    this.mapTarget.style.display = "none";
    this.mapTarget.classList.remove("map-container");
    this.isMapOpen = false; // Mettez à jour l'état lorsque la map est fermée

    if (this.hasIndexPropertyMenuTarget) {
      this.indexPropertyMenuTarget.classList.remove('hidden-no-scroll', 'hidden');
    }
    if (this.hasFlipCardTarget) {
      this.flipCardTarget.classList.remove('hidden-no-scroll')
    }
    this.closePropertyCard();
    this.restoreVisibilityAfterTurboRender();
  }

  cardHover(event) {
    const propertyId = event.currentTarget.id;
    const propertyObject = event.currentTarget.getAttribute('data-property-object');
    const bienId = `marker-${propertyId}`;
    const selectedMarker = this.defaultMarkerTargets.find(marker => marker.id === bienId);
    const price = selectedMarker.children[0];
    const otherMarkers = this.defaultMarkerTargets.filter(marker => marker.id !== bienId);
    const isSelected = sessionStorage.getItem(bienId);

    if(event.type === 'mouseenter') {
      otherMarkers.forEach(marker => marker.classList.add('d-none'));
      if (propertyObject !== "work") {
        price.classList.remove('d-none');
        isSelected ? selectedMarker.classList.remove('selected-marker') : selectedMarker.classList.remove('default-marker');
        isSelected ? selectedMarker.classList.add('selected-hovered-marker') : selectedMarker.classList.add('hovered-marker');
      }
    } else if (event.type === 'mouseleave') {
      otherMarkers.forEach(marker => marker.classList.remove('d-none'));
      if (propertyObject !== "work") {
        price.classList.add('d-none');
        isSelected ? selectedMarker.classList.remove('selected-hovered-marker') : selectedMarker.classList.remove('hovered-marker');
        isSelected ? selectedMarker.classList.add('selected-marker') : selectedMarker.classList.add('default-marker');
      }
    }
  }

  markerHover(event) {
    const ids = this.cardsTargets.map(card => card.id);
    const lastId = ids.slice(-1);
    const twoLastIds = ids.slice(-2);
    const bienId = event.currentTarget.id.replace('marker-', ''); // Enlever le préfixe pour correspondre à l'ID de la carte
    const selectedMarker = event.currentTarget;
    const price = selectedMarker.children[0];
    const otherMarkers = this.defaultMarkerTargets.filter(marker => marker.id !== `marker-${bienId}`); // Utiliser l'ID complet pour la comparaison
    const card = this.cardsTargets.find(card => card.id === bienId);
    if (!card) return; // Si la carte n'est pas trouvée, arrêter l'exécution pour éviter des erreurs

    const propertyObject = card.getAttribute('data-property-object');
    const isSelected = sessionStorage.getItem(`marker-${bienId}`);

    if (event.type === 'mouseover') {
      otherMarkers.forEach(marker => marker.classList.add('d-none'));
      if (propertyObject !== "work") {
        price.classList.remove('d-none');
        isSelected ? selectedMarker.classList.remove('selected-marker') : selectedMarker.classList.remove('default-marker');
        isSelected ? selectedMarker.classList.add('selected-hovered-marker') : selectedMarker.classList.add('hovered-marker');
        isSelected ? card.classList.add('hovered-selected-card') : card.classList.add('hovered-card');
      }
    } else if (event.type === 'click') {
      // Vérifier si le marker est déjà sélectionné
      if (!isSelected) {
        sessionStorage.setItem(`marker-${bienId}`, 'selected');
      }
      selectedMarker.classList.remove('hovered-marker');
      card.classList.remove('hovered-card')
      selectedMarker.classList.add('selected-hovered-marker');
      card.classList.add('hovered-selected-card')

      // Utiliser scrollIntoView avec condition sur le nombre de cartes
      if (ids.length === 3) {
        card.scrollIntoView({ behavior: "smooth", block: `${lastId.includes(card.id) ? 'end' : 'start'}` });
      } else if (ids.length < 3) {
        card.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        card.scrollIntoView({ behavior: "smooth", block: `${twoLastIds.includes(card.id) ? 'end' : 'start'}` });
      }
    } else if (event.type === 'mouseout') {
      otherMarkers.forEach(marker => marker.classList.remove('d-none'));
      if (propertyObject !== "work") {
        price.classList.add('d-none');
        isSelected ? card.classList.remove('hovered-selected-card') : card.classList.remove('hovered-card');
        selectedMarker.classList.remove('hovered-marker');
        selectedMarker.classList.remove('selected-hovered-marker');
        isSelected ? selectedMarker.classList.add('selected-marker') : selectedMarker.classList.add('default-marker');
      }
    }
  }

  renderGeoSearch = (renderOptions, isFirstRender) => {
    const { items, currentRefinement, refine } = renderOptions;

    if (isFirstRender) {
      if (isMobileOrTablet) {
        map = new mapboxgl.Map({
          container: document.getElementById('property-index-map'),
          style: "mapbox://styles/mapbox/streets-v12"
        });

        // Add zoom and rotation controls to the map.
        const navigationControl = new mapboxgl.NavigationControl({
          showCompass: true, // Affiche uniquement la boussole
          showZoom: true // Désactive les boutons de zoom
        });
        map.addControl(navigationControl);
      } else {
        map = new mapboxgl.Map({
          container: document.getElementById('property-index-map'),
          style: "mapbox://styles/mapbox/streets-v12",
        });

        // Add zoom and rotation controls to the map.
        const navigationControl = new mapboxgl.NavigationControl({
          showCompass: false, // Affiche uniquement la boussole
          showZoom: true // Désactive les boutons de zoom
        });
        map.addControl(navigationControl);
      }

      // Configuration initiale de la map et des controls
      if(currentRefinement) {
        const southWest = Object.values(currentRefinement.southWest).reverse();
        const northEast = Object.values(currentRefinement.northEast).reverse();
        map.fitBounds([southWest, northEast], { padding: 70, maxZoom: 15, duration: 0 });
      }

      if (!document.getElementById("geocoder").hasChildNodes()) {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: `${i18n.t('meilisearch.enter_location')}`,
          countries: 'FR', // Limite les recherches à la France
          marker: false, // Désactive l'ajout automatique du marqueur
        });

        document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

        geocoder.on('results', (e) => {
          if (e) {
            // Envoyer une requête POST pour enregistrer l'utilisation
            const csrfToken = document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
            fetch('/application_costs/geocoder', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
              }
            }).then(response => {
              if (!response.ok) {
                console.error('Erreur lors de l\'enregistrement de l\'utilisation de la carte statique');
              }
            }).catch(error => {
              console.error('Erreur réseau :', error);
            });
          }
        });

        // Écouteur d'événements pour l'autocomplétion de Mapbox
        geocoder.on('result', (e) => {
          if (isMobileOrTablet) {
            document.getElementById('geocoder-spinner').style.display = 'block';
          }

          const { result } = e;

          // Utilisation du bounding box pour définir les limites
          if (result.bbox) {
            const [west, south, east, north] = result.bbox;

            // Ajuste la carte pour couvrir la zone spécifiée
            map.fitBounds([[west, south], [east, north]], { padding: 70, duration: 0 });

            // Raffine les résultats pour inclure uniquement les biens dans la zone
            refine({
              northEast: { lat: north, lng: east },
              southWest: { lat: south, lng: west },
            });
          } else if (result.geometry) {
            // Si bbox n'est pas disponible, utilisez un point central avec un zoom adapté
            const { coordinates } = result.geometry;
            const [lng, lat] = coordinates;
            const desiredZoomLevel = 11; // Ajustez selon vos besoins

            map.jumpTo({ center: [lng, lat], zoom: desiredZoomLevel });

            // Définir des limites approximatives autour du point central
            const bounds = map.getBounds();
            const ne = bounds._ne;
            const sw = bounds._sw;

            refine({
              northEast: { lat: ne.lat, lng: ne.lng },
              southWest: { lat: sw.lat, lng: sw.lng },
            });
          }

          if (isMobileOrTablet) {
            document.getElementById('geocoder-spinner').style.display = 'none';
          }
        });
      }

      // Supprimez l'ancien gestionnaire d'événements 'moveend' si existant
      map.off('moveend');

      // Envoyer une requête POST pour enregistrer l'utilisation
      const csrfToken = document.head.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
      fetch('/application_costs/track_dynamic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      }).then(response => {
        if (!response.ok) {
          console.error('Erreur lors de l\'enregistrement de l\'utilisation de la carte statique');
        }
      }).catch(error => {
        console.error('Erreur réseau :', error);
      });
    }

    // Supprimer les anciens markers
    markers.forEach(marker => marker.remove());
    markers = [];

    // Créer de nouveaux markers
    markers = items.map((item) => {
      const isSelected = sessionStorage.getItem(`marker-${item.id}`) === 'selected';
      const markerClass = isSelected ? (isMobileOrTablet ? 'selected-mobile-marker' : 'selected-marker') : (isMobileOrTablet ? 'mobile-marker' : 'default-marker');

      const markerDiv = document.createElement("div");
      markerDiv.innerHTML = `
        <div id="marker-${item.id}" data-meilisearch-property-target="defaultMarker" data-action="${isMobileOrTablet ? "click->meilisearch-property#showRenderProperty" : "mouseout->meilisearch-property#markerHover click->meilisearch-property#markerHover mouseover->meilisearch-property#markerHover"}" class="${markerClass}">
          <span class="${isMobileOrTablet ? 'd-block' : 'd-none'}" data-meilisearch-property-target="priceMarker">${item.object === "work" ? " " : Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(item.condition.total_price_including_charges)}</span>
        </div>
      `;

      const marker = new mapboxgl.Marker(markerDiv)
        .setLngLat([item._geo.lng, item._geo.lat])
        .addTo(map);

      return marker;
    });

    this.propertySelection(items.map(property => property.id).filter(id => id)); // Sélectionnez les propriétés

    if (markers.length > 0) {
      if (!currentRefinement) {
        const bounds = new mapboxgl.LngLatBounds()
        items.forEach(({ _geo }) => bounds.extend([ _geo.lng, _geo.lat ]))
        map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
      }

      const updateButton = document.getElementById('update-button');
      if (updateButton) { // Vérification de l'existence de l'élément
        updateButton.addEventListener('click', () => {
          const ne = map.getBounds()._ne;
          const sw = map.getBounds()._sw;

          refine({
            northEast: { lat: ne.lat, lng: ne.lng },
            southWest: { lat: sw.lat, lng: sw.lng },
          });
        });
      }
    }
  }

  async propertySelection(propertyIds) {
    try {
      const response = await fetch("/property_selections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ property_selection: { property_ids: propertyIds } })
      })

      const data = await response.json()
      if (response.ok) {
        // Mettre à jour le formulaire avec le selection_id
        document.getElementById("propertySelectionId").value = data.selection_id
      } else {
        // Gérer les erreurs
        alert(data.errors.join(", "))
      }
    } catch (error) {
      console.error("Erreur lors de la soumission de la sélection :", error)
    }
  }

  async showRenderProperty(event) {
    NProgress.start();

    try {
      const markerId = event.currentTarget.id;
      const marker = event.currentTarget;

      // Enlever le préfixe 'marker-' pour obtenir l'ID de la propriété
      const propertyId = markerId.replace('marker-', '');

      // Vérifier si le marker est déjà sélectionné
      if (!marker.classList.contains('selected-mobile-marker')) {
        // S'il n'est pas sélectionné, le sélectionner
        marker.classList.add('selected-mobile-marker');
        marker.classList.remove('mobile-marker');
        sessionStorage.setItem(`marker-${markerId}`, 'selected');  // Stocker l'état sélectionné dans sessionStorage
      }

      // Procéder avec l'affichage des détails de la propriété
      const property = await this.findPropertyById(propertyId);
      let propertyHtml = this.renderProperty(property);

      // Choisissez un conteneur où vous voulez insérer la carte clonée. Par exemple, un élément avec l'ID 'propertiesContainer'.
      let container = document.getElementById('propertyDetailsContent');
      container.innerHTML = ''; // Nettoyage du contenu précédent

      // Insérez la carte dans le conteneur.
      container.insertAdjacentHTML('beforeend', propertyHtml);

      // Ajouter la class index-picture au carousel
      let carousel = container.querySelector('.swiper');
      carousel.classList.add('index-picture')

      // Modifier l'id du carousel
      const idCarouselContainer = container.querySelector(`#carouselIndexPicture-${propertyId}`)
      idCarouselContainer.id = `carouselMapPicture-${propertyId}`
      idCarouselContainer.removeAttribute("data-controller")

      const slides = container.querySelectorAll('.swiper-slide');
      // Compter le nombre de slides dans le carrousel cloné
      const hasManyImages = slides.length > 5;

      // container.querySelectorAll('.swiper-slide img').forEach(image => {
      //   image.addEventListener('click', (event) => {
      //     event.preventDefault();
      //     this.openDetails(event, propertyId);
      //   });
      // });

      const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);
      // Récupération de l'index initial de l'image à partir de l'attribut data-image-index
      const initialIndex = swiperContainer.hasAttribute('data-image-index') ? parseInt(swiperContainer.getAttribute('data-image-index'), 10) : 0;

      // Réinitialiser le plugin Swiper sur le clone
      const swiperInstance = new Swiper(container.querySelector('.swiper'), {
        loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
        spaceBetween: 10, // Espace entre les slides
        pagination: {
          el: ".swiper-pagination", // Élément pour la pagination
          type: 'fraction', // Type de pagination
        },
        navigation: {
          nextEl: ".swiper-button-next", // Bouton suivant
          prevEl: ".swiper-button-prev", // Bouton précédent
        },
        zoom: {
          maxRatio: 3, // Maximum zoom
        },
        initialSlide: initialIndex,
      });

      const originalSwiperController = this.application.getControllerForElementAndIdentifier(swiperContainer, "swiper"); // Assurez-vous que cet identifiant correspond
      if (originalSwiperController) {
        originalSwiperController.synchronizeWith(swiperInstance);
      }

      // La carte de détails est visible
      const propertyDetails = document.getElementById('propertyMobileModal');
      if (propertyDetails) {
        propertyDetails.style.display = 'block'; // Ou tout autre style nécessaire pour la rendre visible
      }

      // Ajustez la taille du conteneur de la carte si nécessaire
      this.adjustMapSizeForPropertyDetails(property);
      NProgress.done();
    } catch (error) {
      console.error("Erreur lors de la récupération de la propriété:", error);
      NProgress.done();
    };
  }

  adjustMapSizeForPropertyDetails(property) {
    // Calculer la hauteur de la fenêtre
    const windowHeight = window.innerHeight;

    // Obtenir la hauteur du `.index-picture`
    const indexPictureHeight = document.querySelector('.index-picture').offsetHeight;

    // Calculer la hauteur du `.card-body` en tenant compte du padding
    const cardBodyComputedStyle = window.getComputedStyle(document.querySelector('.card-body'));
    const cardBodyPaddingTop = parseInt(cardBodyComputedStyle.paddingTop, 10);
    const cardBodyPaddingBottom = parseInt(cardBodyComputedStyle.paddingBottom, 10);
    const cardBodyHeight = document.querySelector('.card-body').offsetHeight + cardBodyPaddingTop + cardBodyPaddingBottom;

    // Calculer la hauteur totale occupée par la carte de propriété
    const totalPropertyCardHeight = indexPictureHeight + cardBodyHeight;

    // Calculer l'espace restant pour la carte
    const remainingHeightForMap = windowHeight - totalPropertyCardHeight;

    // Ajuster la hauteur du conteneur de la carte
    const mapContainer = document.getElementById('property-index-map');
    mapContainer.style.height = `${remainingHeightForMap}px`;

    // Redimensionner la carte pour s'adapter au nouveau conteneur
    if (map) map.resize();

    // Centrer la carte sur le marker
    if (map && property._geo && property._geo.lat && property._geo.lng) {
      map.easeTo({
        center: [property._geo.lng, property._geo.lat],
        zoom: map.getZoom(), // Garde le niveau de zoom actuel
        essential: true // Cette animation est considérée comme essentielle par rapport à prefers-reduced-motion
      });
    }
  }

  async openDetails(event) {
    let propertyRedirectUrl = event.currentTarget.dataset.propertyRedirectUrl;
    window.open(`${propertyRedirectUrl}`, '_blank');
  }

  // Méthode pour rechercher une propriété par ID
  async findPropertyById(propertyId) {
    if (!this.meiliSearchClient) {
        console.error("Le client MeiliSearch n'est pas initialisé.");
        return;
    }

    try {
        // L'appel à MeiliSearch renvoie une promesse qui résout dans un objet contenant un tableau de résultats
        const response = await this.meiliSearchClient.search([{ indexName: 'Property', params: { filters: `id=${propertyId}` } }]);

        // Corrigez l'accès aux hits ici
        const hits = response.results[0]?.hits;

        if (hits && hits.length > 0) {
            return hits[0]; // Si 'hits' est présent et contient au moins un résultat, retourne le premier hit
        } else {
            console.log("Aucune propriété trouvée avec cet ID.");
            return null; // Gérez le cas où aucune propriété n'est trouvée
        }
    } catch (error) {
        console.error("Erreur lors de la recherche dans MeiliSearch :", error);
        return null;
    }
  }

  renderProperty(property) {
    const locale = document.documentElement.lang;
    const loanAnnualRate = (this.loanRateValue / 100) / 12;
    const totalSellingPrice = property.condition.total_price_including_charges;
    const monthlyLoanPayment = ((totalSellingPrice - this.contributionAmountValue) * loanAnnualRate / (1 - (1 + loanAnnualRate) ** - (this.repaymentPeriodValue * 12)));

    const formattedMonthlyLoanPayment = Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.ceil(monthlyLoanPayment));

    let price;
    if (property.object === "sell") {
      price = Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(property.condition.total_price_including_charges);
    } else if (property.object === "rent") {
      price = new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(property.condition.total_price_including_charges) + `${i18n.t('simple_form.per_month')}`;
    }

    const formattedPricePerMeter = Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(property.condition.price_per_meter);

    const monthlyLoanPaymentElement = monthlyLoanPayment > 0
      ? `${formattedMonthlyLoanPayment}/${i18n.t('shared.month')}`
      : `${i18n.t('meilisearch.sufficient_financial_contribution')}`;

    const monthlyLoanPaymentHtml = (this.isUserLoggedInValue && !this.isInventoryValue && !this.isInventoryCompanyValue && !this.isRentValue)
      ? `<p class="text-danger mb-0"><small>${monthlyLoanPaymentElement}</small></p>`
      : '';

    const mainRoomNumber = property.description.main_room_number;
    const bedroomNumber = property.description.bedroom_number;
    const bathroomNumber = property.description.bathroom_number;
    const dataAction = isMobileOrTablet ? '' : 'data-action="mouseenter->meilisearch-property#cardHover mouseleave->meilisearch-property#cardHover"';
    const redirectUrl = property.ready_to_go_online ? (property.object === "sell" ? `/${locale}/properties/${property.id}/buy_show` : property.object === "rent" ? `/${locale}/properties/${property.id}/rent_show` : `/${locale}/properties/${property.id}/work_show`) : `/${locale}/properties/${property.id}/edit`;

    const carouselId = `carouselIndexPicture-${property.id}`;
    const defaultImageUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1717749099/development/Seed/ooe4wifkguwqpotchohx.png";

    // Vérifiez si property.photos est défini et est un tableau
    const photos = Array.isArray(property.photos) ? property.photos : [];

    const carouselItems = (photos.length ? photos : [defaultImageUrl]).map((imageUrl, index) => {
      const isDefaultImage = imageUrl === defaultImageUrl;
      const imageClass = isDefaultImage ? 'default-image' : 'w-100 h-100';
      const containerClass = isDefaultImage ? 'default-image-container' : '';
      return `
        <div class="swiper-slide ${containerClass}">
          <div class="swiper-zoom-container">
            <img src="${imageUrl}" class="${imageClass}" alt="${i18n.t('meta.alt.property_index', { property_type_and_object: this.#formatTypeAndObject(property), price: price, zipcode: property.zipcode, city: property.city})}">
          </div>
        </div>
      `;
    }).join('');

    const footerContent = (!property.ready_to_go_online)
      ? `<p class="footer-card text-danger"><small>${i18n.t('meilisearch.missing_elements')}</small></p>`
      : (!property.on_line)
        ? `<p class="footer-card text-warning"><small>${i18n.t('meilisearch.ad_off_line')}</small></p>`
        : (this.isInventoryValue || this.isInventoryCompanyValue || property.object === 'work')
          ? `<p class="footer-card text-success"><small>${i18n.t('meilisearch.ad_on_line')}</small></p>`
          : `<p class="footer-card text-success"><small>${
              property.company.name
                ? `${i18n.t('meilisearch.sell_by')} ${property.company.name.toUpperCase()}`
                : `${i18n.t('meilisearch.sell_by_individual')}`
            }</small></p>`;

    return `
      <div class="card-property" data-meilisearch-property-target="cards" id="${property.id}" ${dataAction}>
        <div id="${carouselId}" class="index-picture" data-controller="swiper" data-swiper-property-index-value="true">
          <div class="swiper">
            <div class="swiper-wrapper">
              ${carouselItems}
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div class="card-body" data-property-id="${property.id}" data-action="click->meilisearch-property#openDetails" data-property-object="${property.object}" data-property-redirect-url="${redirectUrl}" style="cursor: pointer">
          <div>
            ${property.object !== "work" ? `
              <h5 class="text-danger mb-0">${price}</h5>
              <div class="d-flex justify-content-start">
                <p class="text-danger mb-0 ${this.isUserLoggedInValue ? 'me-2' : ''}"><small>${formattedPricePerMeter}${i18n.t('simple_form.per_square_meter_html')}</small></p>
                ${monthlyLoanPaymentHtml}
              </div>
            ` : ''}
            <div class="d-flex mt-2">
            ${
              property.type_property === "land"
                ? `
                  ${property.description.bounded_ground ? `<span class="property-description">${i18n.t('meilisearch.bounded_ground_true_html')}</span>` : ''}
                  ${property.description.fully_serviced ? `<span class="property-description">${i18n.t('meilisearch.fully_serviced_true_html')}</span>` : ''}
                  ${property.description.total_area ? `<span><strong>${property.description.total_area}</strong> ${i18n.t('simple_form.square_meter_html')}</span>` : ''}
                `
                : `
                  ${property.description.main_room_number ? `<span class="property-description">${i18n.t('meilisearch.main_room_html', { count: mainRoomNumber })}</span>` : `<span class="property-description"><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('meilisearch.main_room')}</span>`}
                  ${property.description.bedroom_number ? `<span class="property-description"><strong>${bedroomNumber}</strong> ${i18n.t('meilisearch.bedroom')}</span>` : `<span class="property-description"><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('meilisearch.bedroom')}</span>`}
                  ${property.description.bathroom_number ? `<span class="property-description"><strong>${bathroomNumber}</strong> ${i18n.t('meilisearch.bathroom')}</span>` : `<span class="property-description"><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('meilisearch.bathroom')}</span>`}
                  ${property.description.total_area ? `<span><strong>${property.description.total_area}</strong> ${i18n.t('simple_form.square_meter_html')}</span>` : `<span><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('simple_form.square_meter_html')}</span>`}
                `
            }
            </div>
            <p class="m-0 text-start"><small>${this.#formatTypeAndObject(property)} - ${property.zipcode} ${property.city}</small></p>
            ${footerContent}
          </div>
        </div>
      </div>
    `;
  }

  renderSortBy = (renderOptions, isFirstRender) => {
    const { options, refine, currentRefinement, widgetParams } = renderOptions;

    // Assurez-vous que container est un élément DOM
    const container =
      typeof widgetParams.container === 'string'
        ? document.querySelector(widgetParams.container)
        : widgetParams.container;

    if (!container) {
      console.error('Container not found for customSortBy widget');
      return;
    }

    if (isFirstRender) {
      container.innerHTML = options
        .map(
          (option) => `
            <label style="display: block; margin-bottom: 8px;">
              <input
                type="radio"
                name="sort-by"
                value="${option.value}"
                ${option.value === currentRefinement ? 'checked' : ''}>
              ${option.label}
            </label>
          `
        )
        .join('');

      // Ajouter un événement pour écouter les changements de sélection
      container.addEventListener('change', (event) => {
        if (event.target.name === 'sort-by') {
          refine(event.target.value);
        }
      });
    } else {
      // Mettre à jour l'état des radio-boutons selon le tri actuel
      const inputs = container.querySelectorAll('input[name="sort-by"]');
      inputs.forEach((input) => {
        input.checked = input.value === currentRefinement;
      });
    }
  };

  renderStats = (renderOptions, isFirstRender) => {
    const {
      nbHits,
      areHitsSorted,
      nbSortedHits,
      query,
      widgetParams,
    } = renderOptions;

    if (isFirstRender) {
      return;
    }

    let count = '';

    if (areHitsSorted) {
      if (nbSortedHits > 1) {
        count = `${nbSortedHits} ${i18n.t('meilisearch.relevant_results')}`;
      } else if (nbSortedHits === 1) {
        count = `${i18n.t('meilisearch.one_relevant_result')}`;
      } else {
        count = `${i18n.t('meilisearch.no_relevant_result')}`;
      }
      count += ` ${i18n.t('meilisearch.sorted_on')} ${nbHits}`;
    } else {
      if (nbHits > 1) {
        count += `${nbHits} ${i18n.t('meilisearch.properties')}`;
      } else if (nbHits === 1) {
        count += `${i18n.t('meilisearch.one_property')}`;
      } else {
        count += `${i18n.t('meilisearch.no_property')}`;
      }
    }

    if (nbSortedHits > 1) {
      widgetParams.container.innerHTML = `
      <span class="MyCustomStatsText">${i18n.t('shared.display')} ${count}
      ${query ? `${i18n.t('meilisearch.for')} <q>${query}</q>` : ''}</span>
    `;
    } else {
      widgetParams.container.innerHTML = `
        <span class="MyCustomStatsText">${i18n.t('shared.display')} ${count}
        ${query ? `${i18n.t('meilisearch.for')} <q>${query}</q>` : ''}</span>
      `;
    }
  };

  renderTitleStats = (renderOptions, isFirstRender) => {
    const {
      nbHits,
      widgetParams,
    } = renderOptions;

    if (isFirstRender) {
      return;
    }

    if (this.isInventoryValue || this.isInventoryCompanyValue) {
      const titleKey = 'meilisearch.properties_to_inventory';
      const title = i18n.t(titleKey, { count: nbHits });
      widgetParams.container.innerHTML = `<h1>${title}</h1>`;
      } else if (this.isBuyValue) {
      const titleKey = 'meilisearch.properties_to_buy';
      const title = i18n.t(titleKey, { count: nbHits });
      widgetParams.container.innerHTML = `<h1>${title}</h1>`;
      } else if (this.isRentValue){
      const titleKey = 'meilisearch.properties_to_rent';
      const title = i18n.t(titleKey, { count: nbHits });
      widgetParams.container.innerHTML = `<h1>${title}</h1>`;
    }
  };

  renderCurrentRefinements = (renderOptions, isFirstRendering) => {
    const { items, widgetParams } = renderOptions;

    // Sélection du conteneur
    const container = widgetParams.container;

    // Calculer le nombre total de refinements
    const selectedLabelsCount = items.reduce((count, item) => {
      return count + item.refinements.length;
    }, 0);

    // Mettre à jour le conteneur avec uniquement le nombre entre parenthèses
    container.innerHTML = selectedLabelsCount > 0
      ? `<small>(${selectedLabelsCount})</small>`
      : ''; // Vide si aucun filtre sélectionné
  };

  renderClearRefinements = (renderOptions, isFirstRender) => {
    const { canRefine, refine, widgetParams } = renderOptions;
    const container = this.element.querySelector('#clear-refinements');

    if (isFirstRender) {
      const button = document.createElement('button');
      button.classList.add('btn', 'btn-outline-danger', 'btn-sm', 'rounded');
      button.textContent = i18n.t('meilisearch.delete_filter');

      button.addEventListener('click', () => {
        refine();
      });

      container.appendChild(button);
    }

    const button = container.querySelector('button');

    // Désactiver le bouton si aucun affinage n'est possible
    button.disabled = !canRefine;

    // Mise à jour du texte du bouton selon l'état
    button.textContent = canRefine
      ? i18n.t('meilisearch.delete_filter')
      : i18n.t('meilisearch.no_filter_selected');

    // Mettre à jour les classes en fonction de l'état
    if (canRefine) {
      button.classList.remove('btn-outline-danger');
      button.classList.add('btn-danger', 'text-white');
    } else {
      button.classList.remove('btn-danger', 'text-white');
      button.classList.add('btn-outline-danger');
    }
  };

  renderRangeInput = (renderOptions, isFirstRender, suffixText, maxNumberInput, containerDiv) => {
    const { range, refine, widgetParams } = renderOptions;

    const container = containerDiv.querySelector('.ais-Panel-body');

    // Fonction pour formater les nombres pour les placeholders
    const formatNumber = (number) => {
      const lang = document.documentElement.lang || 'en-US'; // Valeur par défaut si lang est manquant
      try {
          const formatter = new Intl.NumberFormat(lang, {
              useGrouping: true, // Activer les séparateurs de milliers
              maximumFractionDigits: 0, // Pas de décimales
          });
          return formatter.format(number);
      } catch (e) {
          console.error(`Erreur lors du formatage des nombres : ${e}`);
          return number.toString(); // Fallback en cas d'erreur
      }
    };

    if (isFirstRender) {
      const form = document.createElement('form');
      form.style.display = 'flex';
      form.style.justifyContent = 'center';
      form.style.alignItems = 'center';

      // Créer un groupe d'input pour la valeur minimale avec le symbole "€"
      const inputGroupMin = document.createElement('div');
      inputGroupMin.classList.add('input-group');

      const inputMin = document.createElement('input');
      inputMin.type = 'tel';
      inputMin.name = 'min';
      inputMin.classList.add('form-control', 'currency_integer');
      inputMin.placeholder = formatNumber(range.min);
      inputMin.pattern = "[0-9]*";
      inputMin.setAttribute('data-controller', 'auto-numeric');
      inputMin.setAttribute('data-decimal-places', '0');
      inputMin.setAttribute('data-maximum-value', maxNumberInput);
      inputMin.inputmode = "numeric";
      inputGroupMin.appendChild(inputMin);

      const spanMin = document.createElement('span');
      spanMin.classList.add('input-group-text');
      spanMin.innerHTML = suffixText;
      inputGroupMin.appendChild(spanMin);

      // Créer un groupe d'input pour la valeur maximale avec le symbole "€"
      const inputGroupMax = document.createElement('div');
      inputGroupMax.classList.add('input-group');

      const inputMax = document.createElement('input');
      inputMax.type = 'tel';
      inputMax.name = 'max';
      inputMax.classList.add('form-control', 'currency_integer');
      inputMax.placeholder = formatNumber(range.max);
      inputMax.pattern = "[0-9]*";
      inputMax.setAttribute('data-controller', 'auto-numeric');
      inputMax.setAttribute('data-decimal-places', '0');
      inputMax.setAttribute('data-maximum-value', maxNumberInput);
      inputMax.inputmode = "numeric";
      inputGroupMax.appendChild(inputMax);

      const spanMax = document.createElement('span');
      spanMax.classList.add('input-group-text');
      spanMax.innerHTML = suffixText;
      inputGroupMax.appendChild(spanMax);

      // Créer un span pour le texte "à"
      const spanTo = document.createElement('span');
      spanTo.textContent = ' à ';
      spanTo.style.margin = '0 10px';

      // Ajouter les groupes d'input et le span "à" au formulaire
      form.appendChild(inputGroupMin);
      form.appendChild(spanTo);
      form.appendChild(inputGroupMax);
      container.appendChild(form);

      // Fonction pour traiter les changements
      const handleInputBlur = (event) => {
        event.preventDefault();

        const inputMinValue = inputMin.value.replace(/[\s,.-]/g, '');
        const inputMaxValue = inputMax.value.replace(/[\s,.-]/g, '');

        let min = parseFloat(inputMinValue);
        let max = parseFloat(inputMaxValue);

        // Définir les limites autorisées
        const allowedMin = event.target.min; // La valeur minimale autorisée
        const allowedMax = event.target.max; // La valeur maximale autorisée

        // Validation de la valeur minimale
        if (Number.isFinite(min) && ((min < allowedMin) || (min > allowedMax))) {
          alert(i18n.t("meilisearch.value_must_be_between", { min: parseInt(allowedMin, 10).toLocaleString(this.languageValue), max: parseInt(allowedMax, 10).toLocaleString(this.languageValue) }));
          const autoNumericInstanceInputMin = AutoNumeric.getAutoNumericElement(inputMin);
          min = allowedMin; // Corriger la valeur
          autoNumericInstanceInputMin.set(allowedMin);
        }

        // Validation de la valeur maximale
        if (Number.isFinite(max) && ((max < allowedMin) || (max > allowedMax))) {
          alert(i18n.t("meilisearch.value_must_be_between", { min: parseInt(allowedMin, 10).toLocaleString(this.languageValue), max: parseInt(allowedMax, 10).toLocaleString(this.languageValue) }));
          const autoNumericInstanceInputMax = AutoNumeric.getAutoNumericElement(inputMax);
          max = allowedMax; // Corriger la valeur
          autoNumericInstanceInputMax.set(allowedMax);
        }

        // Validation logique entre min et max (min ne doit pas dépasser max)
        if (Number.isFinite(min) && Number.isFinite(max) && min > max) {
          alert(i18n.t("meilisearch.min_value_cannot_be_greater_than_max_value", { max: parseInt(max, 10).toLocaleString(this.languageValue) }));
          const autoNumericInstanceInputMin = AutoNumeric.getAutoNumericElement(inputMin);
          min = max; // Ajuste min pour qu'il ne dépasse pas max
          autoNumericInstanceInputMin.set(max);
        }

        // Appliquer le raffinage avec les valeurs validées
        refine([
          inputMinValue === "" ? undefined : min,
          inputMaxValue === "" ? undefined : max,
        ]);
      };

      // Ajouter les écouteurs d'événements blur
      inputMin.addEventListener('blur', handleInputBlur);
      inputMax.addEventListener('blur', handleInputBlur);

      return;
    }

    // Réinitialiser les valeurs que si nécessaire lors des rendus ultérieurs
    const form = container.querySelector('form');
    if (!form.elements['min'].value) {
      form.elements['min'].placeholder = formatNumber(range.min);
      form.elements['min'].setAttribute('data-maximum-value', maxNumberInput);
      form.elements['min'].min = range.min; // Définit la limite minimum
      form.elements['min'].max = range.max; // Définit la limite maximum
      setTimeout(() => {
        if (Number.isFinite(renderOptions.start[0])) {
          const autoNumericInstanceInputMin = AutoNumeric.getAutoNumericElement(form.elements['min']);
          form.elements['min'].value = renderOptions.start[0];
          autoNumericInstanceInputMin.set(renderOptions.start[0]);
        }
      }, 500);
    }
    if (!form.elements['max'].value) {
      form.elements['max'].placeholder = formatNumber(range.max);
      form.elements['max'].setAttribute('data-maximum-value', maxNumberInput);
      form.elements['max'].min = range.min; // Définit la limite minimum
      form.elements['max'].max = range.max; // Définit la limite maximum
      setTimeout(() => {
        if (Number.isFinite(renderOptions.start[1])) {
          const autoNumericInstanceInputMax = AutoNumeric.getAutoNumericElement(form.elements['max']);
          form.elements['max'].value = renderOptions.start[1];
          autoNumericInstanceInputMax.set(renderOptions.start[1]);
        }
      }, 500);
    }
  };

  #formatTypeAndObject(property) {
    const typeProperty = property.type_property;
    const object = property.object;
    if (typeProperty === "house") {
      if (object === "sell") {
        return `${i18n.t('meilisearch.house_for_sale')}`;
      } else if (object === "rent") {
        return `${i18n.t('meilisearch.house_for_rent')}`;
      } else if (object === "work") {
        return `${i18n.t('meilisearch.house_for_work')}`;
      }
    } else if (typeProperty === "apartment") {
      if (object === "sell") {
        return `${i18n.t('meilisearch.apartment_for_sale')}`;
      } else if (object === "rent") {
        return `${i18n.t('meilisearch.apartment_for_rent')}`;
      } else if (object === "work") {
        return `${i18n.t('meilisearch.apartment_for_work')}`;
      }
    } else if (typeProperty === "land") {
      if (object === "sell") {
        return `${i18n.t('meilisearch.land_for_sale')}`;
      } else if (object === "rent") {
        return `${i18n.t('meilisearch.land_for_rent')}`;
      } else if (object === "work") {
        return `${i18n.t('meilisearch.land_for_work')}`;
      }
    }
    return `${i18n.t('meilisearch.not_disclosed')}`; // Default return value if none of the conditions are met
  }
}
