import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auto-resize"
export default class extends Controller {
  connect() {
    this.resize() // Ajuste la hauteur lors de la connexion
  }

  resize() {
    const textarea = this.element
    textarea.style.height = 'auto' // Réinitialise la hauteur
    const newHeight = Math.min(textarea.scrollHeight, 200) // Limite à 200px
    textarea.style.height = `${newHeight}px`
  }
}
